export function convertIVRtoGraph(
  ivr,
  colMap,
  setColorMap,
  darkMode,
  parentNode = null,
  nodes = [],
  links = [],
  hiddenNodes = new Set()
) {
  console.log("Starting graph conversion with hidden nodes:", Array.from(hiddenNodes));

  const generateNodeId = (node) => {
    if (node?.stepInfo?.userMessages && node?.stepInfo?.userMessages.length > 0) {
      return node.stepInfo.userMessages.join("-");
    }
    return node?.key || "empty";
  }

  if (ivr) {
    const thisNodeKey = generateNodeId(ivr);
    console.log("Processing node:", thisNodeKey, "isHidden:", hiddenNodes.has(thisNodeKey));
    
    if (hiddenNodes.has(thisNodeKey)) {
      console.log("Skipping hidden node:", thisNodeKey);
      return { nodes, links };
    }

    const nodeStatusColors = {
      "Unexplored": "#B0B0B0",       // Light Grey
      "Explored": "#6A8CFF",         // Light Royal Blue
      "Validated": "#4CAF50",        // Light Sea Green
      "Exploration Failed": "#FF6B6B", // Light Red
      "Validation Failed": "#FFA500", // Orange
      "Default": "#E0E0E0"           // Very Light Grey
    };

    const currentState = ivr.stepInfo?.["nodeStatus"] || "Default";
    const nodeColor = nodeStatusColors[currentState] || nodeStatusColors["Default"];

    nodes.push({
      key: thisNodeKey,
      name: ivr?.stepInfo?.systemPrompt || "Unexplored",
      color: nodeColor,
      category: currentState,
      data: ivr
    });

    if (parentNode) {
      links.push({
        from: parentNode,
        to: thisNodeKey,
        text: ivr.stepInfo?.userMessages?.slice(-1)[0]
      });
    }
  
    ivr.submenu && ivr.submenu.forEach(sub => {
      convertIVRtoGraph(
        sub,
        colMap,
        setColorMap,
        darkMode,
        thisNodeKey,
        nodes,
        links,
        hiddenNodes
      );
    });
  
    return { nodes, links };
  }
  return {nodes:[{category: "Default", 
           color: "grey",
           key: "Call Start",
           name: "Call Start"}], links:[]}
}
