import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as go from 'gojs';
import { isEqual } from 'lodash';
import { convertIVRtoGraph } from './convertJson';
import '../../../pages/styles/v2.scss';
import { send_resume_request, send_validation_request, send_export_node_request, send_explore_node_request, send_crawl_from_node_request, send_generate_ai_responses_request, send_export_children_request, send_stop_branch_crawl_request, send_sync_validation_testcase_request, send_retranscribe_with_llm_request, send_delete_node_request, send_create_validation_testcase_with_placeholder_request, send_generate_custom_ai_responses_request } from "../../../backendCalls";
import { TranscriptBox } from '../TranscriptBox.js';
import { Menu, 
         MenuItem,
         TextField,
         Button,
         Dialog, 
         DialogActions, 
         DialogContent, 
         DialogContentText,
         Drawer,
         FormControlLabel,
         ListItemIcon,
         ListItemText,
         Grid,
         Switch,
         DialogTitle,
         List,
         ListItem,
         InputAdornment,
         Paper } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SearchIcon from '@mui/icons-material/Search';
const StateChart = (props) => {
  const {
    graphData,
    setSelectedTreeNode,
    setUtteranceBoolean,
    setConvertedTranscript,
    setOriginalTranscript,
    convertedTranscript,
    originalTranscript,
    darkMode,
    setDiagramDownload,
    setDarkMode,
    hiddenNodes,
    setHiddenNodes
  } = props
  const diagramRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [nodeProcessingAnchorEl, setNodeProcessingAnchorEl] = useState(null);
  const [responseGenerationAnchorEl, setResponseGenerationAnchorEl] = useState(null);
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [systemPromptInput, setSystemPromptInput] = useState("");
  const [menuPosition, setMenuPosition] = useState({ mouseX: null, mouseY: null });
  const [showTranscriptBox, setShowTranscriptBox] = useState(false);
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [audioType, setAudioType] = useState('');
  const [childVisibilityDialogOpen, setChildVisibilityDialogOpen] = useState(false);
  const [childVisibilityCandidates, setChildVisibilityCandidates] = useState([]);
  const [childVisibilityNodeKey, setChildVisibilityNodeKey] = useState(null);
  const [diagramInstance, setDiagramInstance] = useState(null);
  const [childSearchQuery, setChildSearchQuery] = useState('');
  const [customAIPromptDialogOpen, setCustomAIPromptDialogOpen] = useState(false);
  const [customAIPrompt, setCustomAIPrompt] = useState("");

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  const diagramThemeStyle = darkMode ? { backgroundColor: '#121e34', color: 'white' } : { backgroundColor: '#ffffff', color: 'black' };

  // Function to validate a node
  const validateNode = async (node) => {
    console.log("Initiating node validation for:", node);
    try {
      // Retrieve the batch ID from session storage
      let batchId = JSON.parse(sessionStorage.getItem('analysisOutput'));
      
      // Send validation request to the backend
      let response = await send_validation_request(batchId.batchId, node.name);
      
      if (response.status === "error") {
        console.error("Node validation failed:", response.message);
        // TODO: Implement user-facing error handling
      } else {
        console.log("Node validation successful:", response);
        // Trigger update mechanism
        setUtteranceBoolean(true);
      }
    } catch (error) {
      console.error("Error during node validation:", error);
      // TODO: Implement error handling for unexpected errors
    }
  };

  // New function for exporting a node
  const exportNode = async (node) => {
    console.log("Node export initiated for:", node);
    try {
      let batchId = JSON.parse(sessionStorage.getItem('analysisOutput'));
      let response = await send_export_node_request(batchId.batchId, node.name);
      if (response.status === "error") {
        console.error("Node export failed:", response.message);
        // TODO: Implement user-facing error handling
      } else {
        console.log("Node export successful:", response);
        // TODO: Implement success feedback to the user
      }
    } catch (error) {
      console.error("Error during node export:", error);
      // TODO: Implement error handling for unexpected errors
    }
  };

  // New functions for the added menu items
  const exploreNode = async (node) => {
    console.log("Node exploration initiated for:", node);
    try {
      let batchId = JSON.parse(sessionStorage.getItem('analysisOutput'));
      let response = await send_explore_node_request(batchId.batchId, node.name);
      if (response.status === "error") {
        console.error("Node exploration failed:", response.message);
        // TODO: Implement user-facing error handling
      } else {
        console.log("Node exploration successful:", response);

        setUtteranceBoolean(true);
        // TODO: Implement success feedback to the user
      }
    } catch (error) {
      console.error("Error during node exploration:", error);
      // TODO: Implement error handling for unexpected errors
    }
  };

  const crawlFromNode = async (node) => {
    console.log("Crawl from node initiated for:", node);
    try {
      let batchId = JSON.parse(sessionStorage.getItem('analysisOutput'));
      let response = await send_crawl_from_node_request(batchId.batchId, node.name);
      if (response.status === "error") {
        console.error("Crawl from node failed:", response.message);
        // TODO: Implement user-facing error handling
      } else {
        console.log("Crawl from node successful:", response);
        setUtteranceBoolean(true);
        // TODO: Implement success feedback to the user
      }
    } catch (error) {
      console.error("Error during crawl from node:", error);
      // TODO: Implement error handling for unexpected errors
    }
  };

  const generateAIResponses = async (node) => {
    console.log("Generate AI responses initiated for:", node);
    try {
      let batchId = JSON.parse(sessionStorage.getItem('analysisOutput'));
      let response = await send_generate_ai_responses_request(batchId.batchId, node.name);
      if (response.status === "error") {
        console.error("Generate AI responses failed:", response.message);
        // TODO: Implement user-facing error handling
      } else {
        console.log("Generate AI responses successful:", response);
        setUtteranceBoolean(true);
        // TODO: Implement success feedback to the user
      }
    } catch (error) {
      console.error("Error during generate AI responses:", error);
      // TODO: Implement error handling for unexpected errors
    }
  };

  // New function for exporting children
  const exportChildren = async (node) => {
    console.log("Export children initiated for:", node);
    try {
      let batchId = JSON.parse(sessionStorage.getItem('analysisOutput'));
      let response = await send_export_children_request(batchId.batchId, node.name);
      if (response.status === "error") {
        console.error("Export children failed:", response.message);
        // TODO: Implement user-facing error handling
      } else {
        console.log("Export children successful:", response);
        // TODO: Implement success feedback to the user
      }
    } catch (error) {
      console.error("Error during export children:", error);
      // TODO: Implement error handling for unexpected errors
    }
  };

  // Add this new function
  const syncValidationTestCase = async (node) => {
    console.log("Sync validation testcase initiated for:", node);
    try {
      let batchId = JSON.parse(sessionStorage.getItem('analysisOutput'));
      let response = await send_sync_validation_testcase_request(batchId.batchId, node.name);
      if (response.status === "error") {
        console.error("Sync validation testcase failed:", response.message);
        // TODO: Implement user-facing error handling
      } else {
        console.log("Sync validation testcase successful:", response);
        setUtteranceBoolean(true);
        // TODO: Implement success feedback to the user
      }
    } catch (error) {
      console.error("Error during sync validation testcase:", error);
      // TODO: Implement error handling for unexpected errors
    }
  };

  // Update the retranscribeWithLLM function
  const retranscribeWithLLM = async (node) => {
    console.log("Retranscribe with LLM (Multilingual) initiated for:", node);
    try {
      let batchId = JSON.parse(sessionStorage.getItem('analysisOutput'));
      let response = await send_retranscribe_with_llm_request(batchId.batchId, node.name);
      if (response.status === "error") {
        console.error("Retranscribe with LLM failed:", response.message);
        // TODO: Implement user-facing error handling
      } else {
        console.log("Retranscribe with LLM successful:", response);
        setUtteranceBoolean(true);
        // TODO: Implement success feedback to the user
      }
    } catch (error) {
      console.error("Error during retranscribe with LLM:", error);
      // TODO: Implement error handling for unexpected errors
    }
  };

  // Add this new function
  const stopBranchCrawl = async (node) => {
    console.log("Stop branch crawl initiated for:", node);
    try {
      let batchId = JSON.parse(sessionStorage.getItem('analysisOutput'));
      let response = await send_stop_branch_crawl_request(batchId.batchId, node.name);
      if (response.status === "error") {
        console.error("Stop branch crawl failed:", response.message);
        // TODO: Implement user-facing error handling
      } else {
        console.log("Stop branch crawl successful:", response);
        setUtteranceBoolean(true);
        // TODO: Implement success feedback to the user
      }
    } catch (error) {
      console.error("Error during stop branch crawl:", error);
      // TODO: Implement error handling for unexpected errors
    }
  };

  // Add the new deleteNode function
  const deleteNode = async (node) => {
    console.log("Delete node initiated for:", node);
    try {
      let batchId = JSON.parse(sessionStorage.getItem('analysisOutput'));
      let response = await send_delete_node_request(batchId.batchId, node.name);
      if (response.status === "error") {
        console.error("Delete node failed:", response.message);
        // TODO: Implement user-facing error handling
      } else {
        console.log("Delete node successful:", response);
        setUtteranceBoolean(true);
        // TODO: Implement success feedback to the user
      }
    } catch (error) {
      console.error("Error during delete node:", error);
      // TODO: Implement error handling for unexpected errors
    }
  };

  const createValidationTestcaseWithPlaceholder = async (node) => {
    console.log("Create validation testcase with placeholder initiated for:", node);
    try {
      let batchId = JSON.parse(sessionStorage.getItem('analysisOutput'));
      let response = await send_create_validation_testcase_with_placeholder_request(batchId.batchId, node.name);
      if (response.status === "error") {
        console.error("Create validation testcase with placeholder failed:", response.message);
        // TODO: Implement user-facing error handling
      } else {
        console.log("Create validation testcase with placeholder successful:", response);
        setUtteranceBoolean(true);
        // TODO: Implement success feedback to the user
      }
    } catch (error) {
      console.error("Error during create validation testcase with placeholder:", error);
      // TODO: Implement error handling for unexpected errors
    }
  };

  const handleNodeProcessingOpen = (event) => {
    setNodeProcessingAnchorEl(event.currentTarget);
  };

  const handleResponseGenerationOpen = (event) => {
    setResponseGenerationAnchorEl(event.currentTarget);
  };

  const handleViewOpen = (event) => {
    setViewAnchorEl(event.currentTarget);
  };

  const handleExportOpen = (event) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNodeProcessingAnchorEl(null);
    setResponseGenerationAnchorEl(null);
    setViewAnchorEl(null);
    setExportAnchorEl(null);  // Add this line
    setMenuPosition({ mouseX: null, mouseY: null }); 
  };

  const menuItems = [
    {
      title: 'Node Processing',
      subMenu: [
        {
          title: (d) => d?.stepInfo?.nodeStatus === "Unexplored" ? "Explore Node" : "Re-explore Node",
          action: (d) => {
            if (d === null) {
              console.log("Node is null for Explore Node action");
              return;
            }
            console.log("Node exploration initiated for:", d);
            exploreNode(d);
          },
          disabled: (d) => {
            if (d === null) {
              console.log("Node is null for Explore Node disabled check");
              return true;
            }
            const disabledStatusArray = [];
            return disabledStatusArray.includes(d.stepInfo?.nodeStatus) || (d.submenu && d.submenu.length > 0);
          }
        },
        {
          title: 'Validate Node',
          action: (d) => {
            if (d === null) {
              console.log("Node is null for Validate Node action");
              return;
            }
            console.log("Node validation initiated for:", d);
            validateNode(d);
          },
          disabled: (d) => {
            if (d === null) {
              console.log("Node is null for Validate Node disabled check");
              return true;
            }
            return d.stepInfo?.nodeStatus === "Unexplored" || d.stepInfo?.nodeStatus === "Exploration Failed";
          }
        },
        {
          title: 'Crawl From Node',
          action: (d) => {
            if (d === null) {
              console.log("Node is null for Crawl From Node action");
              return;
            }
            console.log("Crawl from node initiated for:", d);
            crawlFromNode(d);
          },
          // Enable only if the status is "Validated"
          disabled: (d) => {
            if (d === null) {
              console.log("Node is null for Crawl From Node disabled check");
              return true;
            }
            return !(d.stepInfo?.nodeStatus === "Validated" && (!d.submenu || d.submenu.length === 0));
          }
        },
        {
          title: 'Stop Branch Crawl',
          action: (d) => {
            if (d === null) {
              console.log("Node is null for Stop Branch Crawl action");
              return;
            }
            console.log("Stop branch crawl initiated for:", d);
            stopBranchCrawl(d);
          },
          // Enable only if the status is "Crawling"
          disabled: (d) => {
            if (d === null) {
              console.log("Node is null for Stop Branch Crawl disabled check");
              return true;
            }
            return false;
          }
        },
        {
          title: 'Sync Validation Testcase',
          action: (d) => {
            if (d === null) {
              console.log("Node is null for Sync Validation TestCase action");
              return;
            }
            console.log("Sync validation testcase initiated for:", d);
            syncValidationTestCase(d);
          },
          disabled: (d) => {
            if (d === null) {
              console.log("Node is null for Sync Validation TestCase disabled check");
              return true;
            }
            return d.stepInfo?.nodeStatus === "Unexplored" || d.stepInfo?.nodeStatus === "Exploration Failed";
          }
        },
        {
          title: 'Retranscribe with LLM (Multilingual)',
          action: (d) => {
            if (d === null) {
              console.log("Node is null for Retranscribe with LLM (Multilingual) action");
              return;
            }
            console.log("Retranscribe with LLM (Multilingual) initiated for:", d);
            retranscribeWithLLM(d);
          },
          disabled: (d) => {
            if (d === null) {
              console.log("Node is null for Retranscribe with LLM (Multilingual) disabled check");
              return true;
            }
            return d.stepInfo?.nodeStatus === "Unexplored" || d.stepInfo?.nodeStatus === "Exploration Failed" || (d.submenu && d.submenu.length > 0);
          }
        },
        {
          title: 'Delete Node',
          action: (d) => {
            if (d === null) {
              console.log("Node is null for Delete Node action");
              return;
            }
            console.log("Delete node initiated for:", d);
            deleteNode(d);
          },
          disabled: (d) => {
            if (d === null) {
              console.log("Node is null for Delete Node disabled check");
              return true;
            }
            return false;  // Enable for all nodes
          }
        },
        {
          title: 'Create Validation Testcase With Placeholder',
          action: (d) => {
            if (d === null) {
              console.log("Node is null for Create Validation Testcase With Placeholder action");
              return;
            }
            console.log("Create validation testcase with placeholder initiated for:", d);
            createValidationTestcaseWithPlaceholder(d);
          },
          disabled: (d) => {
            if (d === null) {
              console.log("Node is null for Create Validation Testcase With Placeholder disabled check");
              return true;
            }
            // Enable only if the node is unexplored or exploration failed
            return !(d.stepInfo?.nodeStatus === "Unexplored" || d.stepInfo?.nodeStatus === "Exploration Failed");
          }
        },
      ],
      openSubMenu: handleNodeProcessingOpen
    },
    {
      title: 'Response Generation',
      subMenu: [
        {
          title: 'Standard AI Responses',
          action: (d) => {
            console.log("Generate AI responses initiated for:", d);
            generateAIResponses(d);
          },
          disabled: (d) => {
            if (d === null) {
              console.log("Node is null for Standard AI Responses disabled check");
              return true;
            }
            return (d.submenu && d.submenu.length > 0) || d.stepInfo?.nodeStatus === "Unexplored";
          }
        },
        {
          title: 'Custom Response',
          action: () => {
            handlePopupOpen();
          },
          disabled: (d) => {
            if (d === null) {
              console.log("Node is null for Custom Response disabled check");
              return true;
            }
            return d.stepInfo?.nodeStatus === "Unexplored";
          }
        },
        {
          title: 'Custom AI Responses',
          action: () => {
            setCustomAIPromptDialogOpen(true);
            handleClose();
          },
          disabled: (d) => {
            if (d === null) {
              return true;
            }
            return d.stepInfo?.nodeStatus === "Unexplored";
          }
        },
      ],
      openSubMenu: handleResponseGenerationOpen
    },
    {
      title: 'View',
      subMenu: [
        {
          title: 'Manage Child Node Visibility',
          action: (d) => {
            if (d !== null) {
              openChildVisibilityDialog(d);
            }
          },
          disabled: (d) => !d?.submenu || d.submenu.length === 0
        },
        {
          title: 'View Latest Exploration Result',
          action: (d) => {
            if (d?.stepInfo?.explorationResultUrl) {
              window.open(d.stepInfo.explorationResultUrl, '_blank');
            }
          },
          disabled: (d) => {
            if (d === null) {
              return true;
            }
            return !d.stepInfo?.explorationResultUrl;
          }
        },
        {
          title: 'View Latest Validation Result',
          action: (d) => {
            if (d?.stepInfo?.validationResultUrl) {
              window.open(d.stepInfo.validationResultUrl, '_blank');
            }
          },
          disabled: (d) => {
            if (d === null) {
              return true;
            }
            return !d.stepInfo?.validationResultUrl;
          }
        },
        {
          title: 'Play Listen Segment Audio',
          action: async (d) => {
            if (d?.stepInfo?.recordSegmentAudioUrl) {
              setAudioUrl(d.stepInfo.recordSegmentAudioUrl);
              setAudioType('Listen Segment');
              setShowAudioPlayer(true);
            }
          },
          disabled: (d) => {
            if (d === null) {
              return true;
            }
            return !d.stepInfo?.recordSegmentAudioUrl;
          }
        },
        {
          title: 'Play Testcase Audio',
          action: async (d) => {
            if (d?.stepInfo?.testcaseAudioUrl) {
              setAudioUrl(d.stepInfo.testcaseAudioUrl);
              setAudioType('Testcase');
              setShowAudioPlayer(true);
            }
          },
          disabled: (d) => {
            if (d === null) {
              return true;
            }
            return d.stepInfo?.testcaseAudioUrl === "";
          }
        },
        {
          title: 'View Transcript',
          action: () => {
            setShowTranscriptBox(true);
          }
        },
        {
          title: 'View JSON',
          action: (d) => {
            const popupWindow = window.open("", 'window' + Date.now());
            popupWindow.document.write(`
              <html>
              <head>
                <title>${d.name || "Unexplored"}</title>
                <style>
                  body { background-color: #f4f7fd; font-family: 'Courier New', Courier, monospace; }
                  pre { background-color: #f4f4f4; padding: 10px; border: 1px solid #ddd; border-radius: 5px; overflow: auto; }
                  code { color: #333; }
                </style>
              </head>
              <body>
                <h1>${d.name || "Unexplored"}</h1>
                <pre><code>${JSON.stringify(d, null, 2)}</code></pre>
              </body>
              </html>
            `);
            popupWindow.document.close();
          }
        },
      ],
      openSubMenu: handleViewOpen
    },
    {
      title: 'Export',
      subMenu: [
        {
          title: 'Node Testcase',
          action: (d) => exportNode(d),
          disabled: (d) => {
            if (d === null) {
              console.log("Node is null for Node Testcase disabled check");
              return true;
            }
            return d.stepInfo?.nodeStatus === "Unexplored";
          }
        },
        {
          title: 'Child Node Testcases',
          action: (d) => exportChildren(d),
          disabled: (d) => {
            if (d === null) {
              console.log("Node is null for Child Node Testcases disabled check");
              return true;
            }
            if (!d.submenu || d.submenu.length === 0) {
              return true;
            }
            // Check if at least one submenu item has status Explored or Validated
            return !d.submenu.some(child => 
              child.stepInfo?.nodeStatus === "Explored" || 
              child.stepInfo?.nodeStatus === "Validated"
            );
          }
        },
      ],
      openSubMenu: handleExportOpen
    }
  ]

  // Transcript
  function checkTranscript(transcript) {
    return transcript.content === "No content";
  }

  const TranscriptDrawer = (newOpen) => () => {
    setShowTranscriptBox(newOpen);
  };

  // Popup window
  const handlePopupOpen = () => {
    setOpenPopup(true);
    handleClose();
  };

  const handlePopupClose = () => {
    setOpenPopup(false);
  };

  // Right click
  const handleRightClick = (e, obj, node) => {
    if (e.event) {
      e.event.preventDefault();
    }
    e.handled = true;
 
    const viewPoint = e.viewPoint;
    const rect = diagramRef.current.getBoundingClientRect();
    setMenuPosition({ mouseX: viewPoint.x + rect.left, mouseY: viewPoint.y + rect.top });
    setAnchorEl(true);
    handleSelectNode(node);
  }
  const handleMenuItemClick = (action) => {
    action();
    handleClose();
  }

  const generateNodeId = (node) => {
    if (node?.stepInfo?.userMessages && node?.stepInfo?.userMessages.length > 0) {
      return node.stepInfo.userMessages.join("-");
    }
    return node?.key || "empty";
  };

  const handleUtterance = async () => {
    // Batch id, Node name, New message
    handlePopupClose();
    let batchId = JSON.parse(sessionStorage.getItem('analysisOutput'));
    let systemPromptInput = sessionStorage.getItem("systemPromptInput") || "";
    let response = await send_resume_request(batchId.batchId, selectedNode.name, systemPromptInput);
    response.status === "error" && console.error(response.message);
    setUtteranceBoolean(true);
  };

  const handleSelectNode = (node) => {
    const nodeId = generateNodeId(node);
    console.log("node:", node);
    setSelectedTreeNode(node);
    setSelectedNode(node);
    const defaultTranscript = [{ content: "No content", role: "NLU System" }];
    
    // Add error handling for JSON parsing
    let resultsResponse;
    try {
      const storedResults = sessionStorage.getItem('resultsResponse');
      resultsResponse = storedResults ? JSON.parse(storedResults) : null;
    } catch (e) {
      console.warn('Failed to parse resultsResponse from sessionStorage:', e);
      resultsResponse = null;
    }

    const transcriptList = resultsResponse?.sessionResultsArray ?? [];
    const sessionId = node?.stepInfo?.session_id ?? undefined;
    
    console.log("session id: ", sessionId);
    console.log(resultsResponse);
    
    if (sessionId) {
      const transcript = transcriptList.find(item => item.id === sessionId);
      setConvertedTranscript(
        (transcript?.convertedTranscript && transcript.convertedTranscript.length > 0) 
          ? transcript.convertedTranscript 
          : defaultTranscript
      );
      setOriginalTranscript(
        (transcript?.transcript && transcript.transcript.length > 0) 
          ? transcript.transcript 
          : defaultTranscript
      );
    } else {
      setConvertedTranscript(defaultTranscript);
      setOriginalTranscript(defaultTranscript);
    }
  };

  // New function to open the child-visibility dialog
  const openChildVisibilityDialog = (node) => {
    if (!node?.submenu) return;
    
    const nodeName = node.name || "Unnamed Node";
    setChildVisibilityNodeKey(nodeName);
    
    const childrenWithVisibility = node.submenu.map(child => {
      const childKey = generateNodeId(child);
      const userMessage = child.stepInfo?.userMessages?.slice(-1)[0] || "Unnamed Child";
      const childCount = child.submenu?.length || 0;
      const isHidden = hiddenNodes.has(childKey);
      return { 
        nodeKey: childKey,
        name: userMessage,
        childCount,
        isHidden 
      };
    });
    setChildVisibilityCandidates(childrenWithVisibility);
    setChildVisibilityDialogOpen(true);
  };

  const handleToggleAll = (showAll) => {
    const updatedCandidates = childVisibilityCandidates.map(child => ({
      ...child,
      isHidden: !showAll
    }));
    setChildVisibilityCandidates(updatedCandidates);
  };

  const handleCustomAIResponses = async () => {
    console.log("Generate custom AI responses initiated for:", selectedNode);
    try {
      let batchId = JSON.parse(sessionStorage.getItem('analysisOutput'));
      let response = await send_generate_custom_ai_responses_request(
        batchId.batchId, 
        selectedNode.name,
        customAIPrompt
      );
      if (response.status === "error") {
        console.error("Generate custom AI responses failed:", response.message);
      } else {
        console.log("Generate custom AI responses successful:", response);
        setUtteranceBoolean(true);
      }
    } catch (error) {
      console.error("Error during generate custom AI responses:", error);
    }
    setCustomAIPromptDialogOpen(false);
    setCustomAIPrompt("");
  };

  useEffect(() => {
    const $ = go.GraphObject.make;

    let diagram = go.Diagram.fromDiv(diagramRef.current);

    if (!diagram) {
      diagram = $(go.Diagram, diagramRef.current, {
        "undoManager.isEnabled": true,
        "contextMenuTool": null,
        layout: $(go.LayeredDigraphLayout, {
          direction: 90,
          layerSpacing: 100,
          columnSpacing: 50
        }),
        "animationManager.isEnabled": false
      });
      
      // Store the diagram instance for later use
      setDiagramInstance(diagram);

      // Node
      diagram.nodeTemplate = 
      $(go.Node, "Auto", 
        {
          isShadowed: true,
          shadowBlur: 0,
          shadowOffset: new go.Point(5, 5),
          shadowColor: darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
          click: (e, obj) => { 
            console.log("Clicked node full data:", obj?.part?.data?.data);
            handleRightClick(e, obj, obj?.part?.data?.data || {});
          }, // Left Click
          contextClick: (e, obj) => { //handleRightClick(e, obj, obj.part.si.data); 
                                    }, // Right Click
          mouseEnter: (e, obj) => {
            const nodeData = obj.part.data;
            console.log("Node data:", nodeData);
          },
          toolTip: 
            $("ToolTip",
              $(go.TextBlock, { margin: 4 },
                new go.Binding("text", "", d => d.data.name || "Unnamed Node")
              )
            )
        },
        new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
        new go.Binding("hierarchy", "", (v, d) => d.findTreeChildrenNodes().count > 0 ? "parent" : "child").ofObject(),
        $(go.Panel, "Auto",
          $(go.Shape, "Rectangle",
            {
              maxSize: new go.Size(300, NaN),
              minSize: new go.Size(40, 40), 
              strokeWidth: 2,
              cursor: "pointer",  // this Shape is the port, not the whole Node
            },
            new go.Binding("fill", "color"),
            // Ellipse
            new go.Binding("figure", "hierarchy", hierarchy => hierarchy === "parent" ? "Rectangle" : "Rectangle")),
          $(go.TextBlock,
            { margin: 8, 
              editable: false, 
              font: "bold 10pt helvetica, arial, sans-serif",
              name: "TEXT",
              maxSize: new go.Size(270, NaN)},
            new go.Binding("text", "name"))
        )
      );

      // Link node
      diagram.linkTemplate =
        $(go.Link,
          { isShadowed: true,
            shadowBlur: 0,
            shadowColor: 'black',
            shadowOffset: new go.Point(2.5, 2.5),
            curve: go.Curve.Bezier,
          // { routing: go.Routing.Orthogonal, 
            fromEndSegmentLength: 50,
            toEndSegmentLength: 50,
            fromShortLength: 5,
            toShortLength: 10,
            selectable: false,
            fromSpot: go.Spot.Right,
            toSpot: go.Spot.Left,
          },
          $(go.Shape, { name: "LINK_SHAPE", shadowVisible: false, strokeWidth: 2, stroke: "#2F4F4F" }),
          $(go.Shape, { name: "ARROW_SHAPE", shadowVisible: false, toArrow: "Standard", stroke: null, fill: "#2F4F4F" }),
          $(go.Panel, "Auto",
            $(go.Shape, "RoundedRectangle",
              {
                shadowVisible: true,
                maxSize: new go.Size(100, NaN),
                fill: "lightyellow",
                strokeWidth: 0.5,
              }),
            $(go.TextBlock, "transition",
              {
                textAlign: "center",
                maxSize: new go.Size(90, NaN),
                font: '9pt helvetica, arial, sans-serif',
                stroke: "#2F4F4F",
                margin: 1,
                segmentIndex: -1,
                editable: false,
                name: "TEXT"
              },
              new go.Binding("text", "text")),
          )
        );
    }

    console.log("graphData:",graphData);
    if(graphData){
      // Save viewport state
      const oldPosition = diagram.position.copy();
      const oldScale = diagram.scale;

      // Update model
      diagram.model = new go.GraphLinksModel(graphData.nodes, graphData.links);

      // ↓↓↓ Add this if-check to skip layout + zoom when only 1 node remains ↓↓↓
      if (graphData.nodes.length > 1) {
        // Normal layout
        diagram.layoutDiagram(true);
        // Restore viewport
        diagram.scale = oldScale;
        diagram.position = oldPosition;
        // Or optionally diagram.zoomToFit() for multi-node diagrams
      } else {
        // Single node: skip calls that might cause a layout freeze
        diagram.scale = 1;
        diagram.position = new go.Point(0, 0);
      }
      // ↑↑↑ End of fix ↑↑↑
      
      diagram.startTransaction("update shadowColor");
      diagram.nodes.each(function (node) {
        node.isShadowed = true;
        node.shadowColor = darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';
        const textBlock = node.findObject("TEXT");
        if (textBlock) {
          textBlock.stroke = darkMode ? 'white' : 'black';
        }
      });

      diagram.links.each(function(link) {
        const linkShape = link.findObject("LINK_SHAPE");
        if (linkShape) {
          linkShape.stroke = darkMode ? '#e0a420' : '#2F4F4F';
        }
    
        const arrowShape = link.findObject("ARROW_SHAPE");
        if (arrowShape) {
          arrowShape.fill = darkMode ? '#e0a420' : '#2F4F4F';
        }
      });

      diagram.commitTransaction("update shadowColor");
    }

    setDiagramDownload(diagram);
  }, [graphData, darkMode]);

  // New function to handle hiding/showing nodes
  const handleVisibilityConfirm = () => {
    console.log("1. Visibility confirm clicked");
    console.log("2. Current hiddenNodes:", Array.from(hiddenNodes));
    
    const newHiddenSet = new Set(hiddenNodes);
    childVisibilityCandidates.forEach(child => {
      if (child.isHidden) {
        newHiddenSet.add(child.nodeKey);
      } else {
        newHiddenSet.delete(child.nodeKey);
      }
    });
    
    console.log("3. New hidden set:", Array.from(newHiddenSet));
    setChildVisibilityDialogOpen(false);
    
    // Force immediate update
    props.setHiddenNodes(newHiddenSet);
    console.log("4. Called setHiddenNodes");
  };

  return (
    <div>
    <div ref={diagramRef} className="tree-diagram-container"  style={{ height: '75vh', ...diagramThemeStyle }} />
    <FormControlLabel
      control={<Switch checked={darkMode} onChange={toggleTheme} />}
      label="Night Mode"
    />
    <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={{
          top: menuPosition.mouseY,
          left: menuPosition.mouseX
        }}
        disableScrollLock={true}
        MenuListProps={{'aria-labelledby': 'basic-button,'}}
        style={{
          display: menuPosition.mouseX == null ? 'none' : 'block'
        }}
      >
        {menuItems.map((item, index) => (
          item.subMenu ? (
            <MenuItem
              key={index}
              onClick={item.openSubMenu}
              aria-controls={`submenu-${index}`}
              aria-haspopup="true"
            >
              <ListItemText primary={item.title} />
              <ListItemIcon style={{ minWidth: 'auto' }}>
                <ArrowRightIcon fontSize="small" />
              </ListItemIcon>
            </MenuItem>
          ) : (
            <MenuItem key={index} onClick={() => {
              item.action(selectedNode);
              handleClose();
            }}>
              {item.title}
            </MenuItem>
          )
        ))}
      </Menu>
      <Menu
        anchorEl={nodeProcessingAnchorEl}
        open={Boolean(nodeProcessingAnchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuItems[0].subMenu.map((subItem, subIndex) => (
          <MenuItem 
            key={subIndex} 
            onClick={() => {
              subItem.action(selectedNode);
              handleClose();
            }}
            disabled={subItem.disabled ? subItem.disabled(selectedNode) : false}
          >
            {typeof subItem.title === 'function' ? subItem.title(selectedNode) : subItem.title}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={responseGenerationAnchorEl}
        open={Boolean(responseGenerationAnchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuItems[1].subMenu.map((subItem, subIndex) => (
          <MenuItem 
            key={subIndex} 
            onClick={() => {
              subItem.action(selectedNode);
              handleClose();
            }}
            disabled={subItem.disabled ? subItem.disabled(selectedNode) : false}
          >
            {typeof subItem.title === 'function' ? subItem.title(selectedNode) : subItem.title}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={viewAnchorEl}
        open={Boolean(viewAnchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuItems[2].subMenu.map((subItem, subIndex) => (
          <MenuItem 
            key={subIndex} 
            onClick={() => {
              subItem.action(selectedNode);
              handleClose();
            }}
            disabled={subItem.disabled ? subItem.disabled(selectedNode) : false}
          >
            {typeof subItem.title === 'function' ? subItem.title(selectedNode) : subItem.title}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={exportAnchorEl}
        open={Boolean(exportAnchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuItems[menuItems.length - 1].subMenu.map((subItem, subIndex) => (
          <MenuItem 
            key={subIndex} 
            onClick={() => {
              subItem.action(selectedNode);
              handleClose();
            }}
            disabled={subItem.disabled ? subItem.disabled(selectedNode) : false}
          >
            {typeof subItem.title === 'function' ? subItem.title(selectedNode) : subItem.title}
          </MenuItem>
        ))}
      </Menu>
      <Drawer
        anchor="bottom"
        open={showTranscriptBox}
        onClose={TranscriptDrawer(false)}
        disableScrollLock={true}
        >
        <Grid item xs={12} style={{ maxHeight: '80vh', overflow: 'auto' }}>
          <div className="transcript-box" style={{ backgroundColor: 'white', overflowY: 'auto' }}>
            <TranscriptBox showClose={false} originalTranscript={originalTranscript} convertedTranscript={convertedTranscript} />
          </div>
        </Grid>
      </Drawer>
      <Dialog open={openPopup} onClose={handlePopupClose} disableScrollLock={true} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Utterance</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <strong>Node:</strong> {selectedNode?.stepInfo?.systemPrompt || "Unexplored"}
          </DialogContentText>
          <TextField 
            sx={{ marginTop: '10px', background: "white" }}
            label="New User Response"
            variant="outlined"
            type="text"
            value={systemPromptInput}
            onChange={(e) => {setSystemPromptInput(e.target.value);
                              sessionStorage.setItem("systemPromptInput", e.target.value);}}
            multiline
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePopupClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUtterance} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        open={showAudioPlayer} 
        onClose={() => setShowAudioPlayer(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{audioType} Audio</DialogTitle>
        <DialogContent>
          <audio 
            controls 
            src={audioUrl}
            style={{ width: '100%', marginTop: '10px' }}
            autoPlay
          >
            Your browser does not support the audio element.
          </audio>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={async () => {
              try {
                const response = await fetch(audioUrl);
                const blob = await response.blob();
                const audioBlob = new Blob([blob], { type: 'audio/wav' });
                const blobUrl = URL.createObjectURL(audioBlob);
                
                const link = document.createElement('a');
                link.href = blobUrl;
                const nodeName = selectedNode?.name?.replace(/[^a-z0-9]/gi, '_').toLowerCase() || 'unknown_node';
                const timestamp = new Date().toISOString().slice(0,19).replace(/[:-]/g, '');
                const audioTypeSlug = audioType.toLowerCase().replace(' ', '_');
                link.download = `${nodeName}_${audioTypeSlug}_recording_${timestamp}.wav`;
                
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
              } catch (error) {
                console.error('Error downloading audio:', error);
              }
            }} 
            color="primary"
          >
            Download
          </Button>
          <Button onClick={() => setShowAudioPlayer(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={childVisibilityDialogOpen}
        onClose={() => setChildVisibilityDialogOpen(false)}
        disableScrollLock={true}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{`${childVisibilityNodeKey} - Child Node Visibility`}</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Switch
                checked={childVisibilityCandidates.every(child => !child.isHidden)}
                onChange={(e) => handleToggleAll(e.target.checked)}
              />
            }
            label="Show All"
            style={{ width: '100%', marginBottom: '8px' }}
          />
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search child node utterances..."
            value={childSearchQuery}
            onChange={(e) => setChildSearchQuery(e.target.value)}
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Paper style={{ maxHeight: 400, overflow: 'auto', marginTop: 2 }}>
            <List>
              {childVisibilityCandidates
                .map((child, idx) => ({
                  ...child,
                  originalIdx: idx + 1,
                  show: child.name.toLowerCase().includes(childSearchQuery.toLowerCase())
                }))
                .filter(child => child.show)
                .map((child, idx) => (
                  <ListItem key={`${child.nodeKey}_${idx}`} dense>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!child.isHidden}
                          onChange={(e) => {
                            const foundIndex = childVisibilityCandidates.findIndex(
                              c => c.nodeKey === child.nodeKey
                            );
                            if (foundIndex !== -1) {
                              const newCandidates = [...childVisibilityCandidates];
                              newCandidates[foundIndex].isHidden = !e.target.checked;
                              setChildVisibilityCandidates(newCandidates);
                            }
                          }}
                        />
                      }
                      label={`${child.originalIdx}. ${child.name} (${child.childCount} children)`}
                      style={{ width: '100%' }}
                    />
                  </ListItem>
                ))}
            </List>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setChildVisibilityDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleVisibilityConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        open={customAIPromptDialogOpen} 
        onClose={() => setCustomAIPromptDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Custom AI Responses</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <strong>Node:</strong> {selectedNode?.stepInfo?.systemPrompt || "Unexplored"}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Custom AI Prompt"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={customAIPrompt}
            onChange={(e) => setCustomAIPrompt(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCustomAIPromptDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleCustomAIResponses}
            disabled={!customAIPrompt.trim()}
          >
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const FlowDiagram = (props) => {
  const {
    intentTree, 
    colorMap, 
    setColorMap,
    setSelectedTreeNode,
    setUtteranceBoolean,
    setConvertedTranscript,
    setOriginalTranscript,
    convertedTranscript,
    originalTranscript,
    darkMode,
    setDiagramDownload,
    setDarkMode
  } = props

  const [graphData, setGraphData] = useState(null);
  const [hiddenNodes, setHiddenNodes] = useState(new Set());

  const prevIntentTree = useRef();
  const prevDarkMode = useRef();

  // Update hiddenNodes handler in StateChart
  const handleHiddenNodesChange = useCallback((newHiddenSet) => {
    console.log("5. handleHiddenNodesChange called with:", Array.from(newHiddenSet));
    
    // FORCE IMMEDIATE UPDATE
    setHiddenNodes(newHiddenSet);
    setGraphData(prevData => {
      console.log("6. Generating new graph data");
      const defaultColorMap = darkMode ? {"Default": "grey"} : {"Default": "lightgrey"};
      return convertIVRtoGraph(
        intentTree,
        defaultColorMap,
        setColorMap,
        darkMode,
        null,
        [],
        [],
        newHiddenSet
      );
    });
  }, [intentTree, darkMode, setColorMap]);

  // Update the main useEffect to be simpler:
  useEffect(() => {
    if ((intentTree && !isEqual(prevIntentTree.current, intentTree) && Object.keys(intentTree).length > 0) || 
        darkMode !== prevDarkMode.current) {
      console.log("7. Main useEffect triggered");
      const defaultColorMap = darkMode ? {"Default": "grey"} : {"Default": "lightgrey"};
      setGraphData(convertIVRtoGraph(
        intentTree,
        defaultColorMap,
        setColorMap,
        darkMode,
        null,
        [],
        [],
        hiddenNodes
      ));
      prevIntentTree.current = intentTree;
      prevDarkMode.current = darkMode;
    }
  }, [intentTree, darkMode, hiddenNodes, setColorMap]);

  return <StateChart 
    graphData={graphData}
    setSelectedTreeNode={setSelectedTreeNode}
    setUtteranceBoolean={setUtteranceBoolean}
    setConvertedTranscript={setConvertedTranscript}
    convertedTranscript={convertedTranscript}
    originalTranscript={originalTranscript}
    setOriginalTranscript={setOriginalTranscript}
    darkMode={darkMode}
    setDiagramDownload={setDiagramDownload}
    setDarkMode={setDarkMode}
    hiddenNodes={hiddenNodes}
    setHiddenNodes={handleHiddenNodesChange}  // Use the new handler
  />;
}
export default FlowDiagram;
